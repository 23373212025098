<template>
    <div
        :class="{
            'map-round': round,
            'map-focused': focused,
            'map-error': error,
            'map-success': !error && touched,
            'map-dark': dark,
            'map-has-label': label,
            'map-has-icon': has_icon,
            'map-has-left-icon': iconLeft,
            'map-has-right-icon': iconRight,
        }"
        class="map-upload map-input"
        >
        <slot name="label">
            <label v-if="label">{{ label }}</label>
        </slot>
        <div class="map-input-inner">
            <slot name="iconLeft">
                <i class="map-input-icon-left" :class="iconLeft" v-if="iconLeft"></i>
            </slot>
            <slot>
                <label
                    class="map-upload-caption map-caption"
                    :class="{
                        'map-placeholder': !has_file,
                    }"
                    :for="unique_id"
                >
                    {{ this.file.name || placeholder }}
                </label>
                <input
                    :id="unique_id"
                    ref="file"
                    type="file"
                    :accept="accept_types"
                    @change="handle_file_upload"
                    />
            </slot>
            <slot name="iconRight">
                <i class="map-input-icon-right" :class="iconRight" v-if="iconRight"></i>
            </slot>
        </div>
        <slot name="error" v-if="error || $slots.error">
            <p class="map-error">{{ error }}</p>
        </slot>
    </div>
</template>

<script>
    export default
    {
        name: 'upload-input',
        inheritAttrs: false,
        props:
        {
            label: String,
            required: Boolean,
            round: Boolean,
            dark: Boolean,
            placeholder: String,
            modelValue: [String, Number, Object],
            accept: String,
            error:
            {
                type: String,
                default: ''
            },
            iconLeft: String,
            iconRight: String,
        },
        emits: ['update:modelValue', 'file'],
        data()
        {
            return {
                file_name: '',
                focused: false,
                touched: false
            };
        },
        computed:
        {
            file:
            {
                get()
                {
                    return this.modelValue;
                },
                set(value)
                {
                    this.$emit('update:modelValue', value);
                },
            },

            has_icon()
            {
                const { iconLeft, iconRight } = this.$slots;

                return (
                    iconLeft !== undefined ||
                    iconRight !== undefined ||
                    this.iconLeft !== undefined ||
                    this.iconRight !== undefined
                );
            },

            has_file()
            {
                return this.file.name !== undefined || this.file.filename !== undefined;
            },

            listeners()
            {
                return {
                    input: this.on_input,
                    blur: this.on_blur,
                    focus: this.on_focus,
                };
            },
        },
        created()
        {
            this.unique_id = Math
                .random()
                .toString(16)
                .slice(2);

            if (this.accept)
            {
                if (this.accept === 'images')
                {
                    this.accept_types = '.jpg,.png,.webp, images/*';
                }
                else if (this.accept === 'pdf')
                {
                    this.accept_types = '.pdf, application/pdf';
                }
                else
                {
                    this.accept_types = this.accept;
                }
            }
        },
        methods:
        {
            on_input(ev)
            {
                if (!this.touched)
                {
                    this.touched = true;
                }

                this.$emit('update:modelValue', ev.target.value);
            },
            on_focus()
            {
                this.focused = true;
            },
            on_blur()
            {
                this.focused = false;
            },
            handle_file_upload()
            {
                if (this.$refs.file.files.length > 0)
                {
                    this.file = this.$refs.file.files[0];

                    if (this.file_id)
                    {
                        this.file.id = this.file_id;
                    }

                    const max_size = 1024000 * 80; // 80MB

                    console.log(this.file);
                    
                    if (this.file.size > max_size)
                    {
                        this.$toast.error('Maximum allowd upload size is 80MB');

                        return ;
                    }


                    this.$emit('file', this.file);
                }
            },
        }
    }
</script>
